<template>
  <div class="works">
    <left-menu current="Release" @continueSubmission="continueSubmission"></left-menu>
    <div class="main activeMainBg">
      <div class="stepShow">
        <steps
            :step="currentStep"
            :steps="[
          { text: '上传歌曲', icon: { active: 'release/01.png', default: 'release/01.png' } },
          { text: '编辑授权信息', icon: { active: 'release/03.png', default: 'release/04.png' } },
          { text: '提交成功', icon: { active: 'release/05.png', default: 'release/06.png' } },
        ]"
        ></steps>
      </div>
      <div class="titleNav" v-show="currentStep === 1">
        <p class="label">
          发行作品可享受权利：
        </p>
        <p>一、全网发行：一键发行最多150+家平台，给作品更多曝光机会。</p>
        <p>二、更高收入：海葵作为成熟的发行公司，具有高于独立发行的议价权。</p>
      </div>
      <step-one :Authorization="Authorization"  @dataFilling="dataFilling"  @continueSubmission="continueSubmission" @submitAuthorization="submitAuthorization" @albumContentFn="albumContentFn" :currentStep="currentStep" ref="stepOne" v-show="currentStep === 1"></step-one>
      <step-two @continueSubmission="continueSubmission" ref="stepTwo" v-show="currentStep === 2"></step-two>
      <step-three @continueSubmission="continueSubmission" v-show="currentStep === 3"></step-three>
      <div class="footer">
        <el-button @click="currentStep --" v-show="currentStep === 2">上一步</el-button>
        <el-button v-show="showDraft" @click="nextStep(0)" :class="{draftActive:flage1 || flage2}" >保存草稿</el-button>
        <el-button class="active" :class="{draftActive:flage1 || flage2}" v-show="currentStep === 2 || currentStep === 1" @click="nextStep(1)">{{currentStep===2 ? '提交作品' :'下一步'}}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import LeftMenu from "@/components/LeftMenu";
import steps from "@/components/Steps";
import stepOne from './components/stepOne'
import stepTwo from "./components/stepTwo";
import stepThree from "./components/stepThree";

export default {
  name: "release",
  components: {LeftMenu, steps, stepOne, stepTwo, stepThree},
  /**
   * 当前步骤 currentStep
   * */
  computed: {
    showDraft() {
      if (this.currentStep === 2 || this.currentStep === 1) {
        if (this.$route.params.id !== '0') {
            if (this.albumContent && this.albumContent.status == 4) {
              return true
            } else {
              return false
            }
        } else {
          return true
        }
      } else {
        return false
      }
    },
  },
  data() {
    return {
      currentStep: 1,
      albumContent:{},
      flage1:true,
      flage2:true,
      Authorization:false

    }
  },
  methods: {
    nextStep(val) {
      this.$refs.stepOne.submitFn(val)
      if(this.currentStep === 2){
        this.$refs.stepTwo.submitFlay1()
      }
    },
  //获取专辑类容
    albumContentFn(val){
      this.albumContent = val
    },
  //提交
    submitAuthorization(val){
      this.$refs.stepTwo.submitFlay(val)
    },
  // 继续提交按钮
    continueSubmission(){
      this.$refs.stepOne.continueSubmission()
      this.$refs.stepOne.$refs.uploadAlbum.albumObj = {
        upImage:'', //上传图片
        name:'', //专辑名
        singerName:'',//所属艺人
        schools:[],//流派
        language:[],//语言
        introduce:'',//介绍
        issue:'0',//是否发行
        issuePlatform:'' //已发行平台
      }
      // this.$refs.stepOne.$refs.uploadAlbum.isNewBuild = true
    },
    //  数据填充
    dataFilling(val){
      this.$refs.stepTwo.getPopulationData(val)
    },
  },
  watch:{
    currentStep(newV){
      if(newV === 2){
        this.$refs.stepTwo.PublicAddTwoFn(this.$refs.stepOne.songList)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../styles/works';

.activeMainBg {
  background: #f7f7f7;
  padding: 0;
}

.stepShow {
  height: 117px;
  box-sizing: border-box;
  padding: 0 300px;
  background: #ffffff;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.titleNav {
  padding: 24px 40px;
  box-sizing: border-box;

  .label {
    font-size: 12px;
    font-family: PingFang SC-Semibold;
    font-weight: 600;
    color: #666666;
  }

  p {
    margin: 0 0 8px 0;
    font-size: 12px;
    font-family: PingFang SC, PingFang SC-Medium;
    font-weight: 500;
    text-align: LEFT;
    color: #999999;
  }
}

.footer {
  margin-top: 24px;
  display: flex;
  justify-content: center;

  .el-button {
    margin-right: 24px;
    width: 140px;
    height: 44px;
    background: #ffffff;
    border: 1px solid #dedede;
    border-radius: 4px;
    font-family: PingFang SC-Regular;
    font-weight: 400;
    color: #666666;
  }
    .draftActive{
      background: #ff004d;
      opacity: .5;
      cursor: default;
    }
  .draftActive {
    background: #ffffff;
    opacity: .5;
    cursor: default;
  }
  .active {
    background: #ff004d;
    border-radius: 4px;
    font-weight: 500;
    color: #ffffff;
  }
}
</style>
