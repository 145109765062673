<template>
  <div class="big">
    <div class="title-header">上传至专辑</div>
    <div class="selectSlbum" v-if="selectAlbum.id">
      <div class="l">
        <img :src="selectAlbum.cover"> {{selectAlbum.name}}
      </div>
      <img class="r" @click="deleteSelectedAlbum()" src="../../../assets/release/closeX.png">
    </div>
    <div class="unchecked" v-else>
      <div class="choose-album" v-if="isNewBuild">
        <div class="header">
          <div class="left">
            <el-input
                @input="searchFn()"
                v-model="searchName"
                placeholder="请选择专辑">
              <img slot="prefix" class="el-input__icon" src="../../../assets/release/search.png">
            </el-input>
          </div>
          <div class="right">
            <img class="up" @click="newlyAdded()" :src="upDown">
            <div class="line"></div>
            <div class="newAdd" @click="isNewBuild = false">
              <img class="add" src="../../../assets/release/add.png">
              新增专辑
            </div>
          </div>
        </div>
        <div class="content" :class="{activeContent:activeFlag}">
          <div class="newlyBuild" @click="isNewBuild = false">
            <p>+</p>
            <p>新建专辑</p>
          </div>
          <div class="albumItem" v-for="item in albumList" @click="selectItemFn(item)" :key="item.id">
            <img :src="item.cover">
            <p class="title" :title="item.name">{{item.name}}</p>
            <p class="title-name">{{item.albumMusician}}</p>
          </div>
        </div>
      </div>
      <div class="new-built" v-else>
        <div class="header">
          <div class="l">
            {{musicianAlbum.status === 4 ? '编辑专辑': '新建专辑'}}
          </div>
<!--          <img @click="isNewBuildFn" class="r" src="../../../assets/release/closeX.png">-->
        </div>
        <ul class="section">
          <el-form ref="form" :model="albumObj" :rules="rules" label-width="96px">
            <el-form-item label="专辑名称" prop="name">
                <div class="content">
                  <el-input v-model.trim ="albumObj.name" placeholder="请输入专辑名称">
                  </el-input>
                </div>
            </el-form-item>
            <el-form-item label="专辑封面" prop="upImage">
                <div class="content list" style="display: flex;">
                  <img class="uploadImg" v-if="albumObj.upImage" :src="albumObj.upImage">
                  <img class="uploadImg" v-else src="../../../assets/release/uploadDefault.png">
                  <div class="uploadBut">
                    <div class="uploadBut-header">
                      图片需为jpg、jpeg、png格式 尺寸 ≥ 1000*1000像素
                    </div>
                    <el-upload
                        class="upload-demo"
                        :action="uploadApi"
                        :headers="{...headers,fileType:'image'}"
                        :on-success="onSuccessFn"
                        :before-upload="beforeUpload"
                        :show-file-list="false"
                        accept=".jpg,.jpeg,.png"
                        multiple>
                      <el-button size="small" class="active" type="primary">{{albumObj.upImage ? '重新上传' : '上传封面'}}</el-button>
                    </el-upload>
                  </div>
                </div>
            </el-form-item>
            <el-form-item label="所属艺人" prop="singerName">
                <div class="content">
                  <el-input v-model.trim ="albumObj.singerName" placeholder="请输入专辑所属艺人"></el-input>
                </div>
            </el-form-item>
            <el-form-item label="流派" prop="schools">
                <div class="content">
                  <el-select v-model="albumObj.schools" multiple placeholder="请选择流派"   @change="selectChang()">
                    <el-option
                        v-for="item in schoolsList"
                        :key="item.value"
                        :label="item.value"
                        :value="item.value">
                    </el-option>
                  </el-select>
                </div>
            </el-form-item>
            <el-form-item label="语种" prop="schools">
              <div class="content">
                <el-select v-model="albumObj.language" placeholder="请选择语种"   @change="selectChang1()">
                  <el-option
                      v-for="item in languageList"
                      :key="item.value"
                      :label="item.value"
                      :value="item.value">
                  </el-option>
                </el-select>
              </div>
            </el-form-item>
            <el-form-item label=" 专辑介绍" prop="introduce">
                <div class="content">
                  <el-input v-model.trim="albumObj.introduce"  show-word-limit maxlength="2000" type="textarea" resize="none" placeholder="请输入10-2000文字"></el-input>
                </div>
            </el-form-item>
            <el-form-item label="是否已发行" prop="issue">
                <div class="content">
                  <el-select v-model="albumObj.issue"  placeholder="请选择是否发行"
                  >
                    <el-option
                        label="已发行"
                        value="1">
                    </el-option>
                    <el-option
                        label="未发行"
                        value="0">
                    </el-option>
                  </el-select>
                </div>
            </el-form-item>
            <el-form-item label="已发行平台">
                <div class="content">
                  <el-input v-model="albumObj.issuePlatform" placeholder="请输入已发行平台"></el-input>
                </div>
            </el-form-item>
          </el-form>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>

let timer;
import {albumList, albumSubmit } from '../../../api/song'
export default {
  name: "uploadAlbum",
  props:{
    selectAlbum:Object,
    headers:Object,
    schoolsList:Array,
    musicianAlbum:Object,
    languageList:Array,
    songListJudgment:Function
  },
  computed: {
    upDown() {
      if (this.activeFlag) return require('../../../assets/release/up.png')
      else return require('../../../assets/release/down.png')
    }
  },
  data() {
    let validatePass = (rule, value, callback) => {
      console.log(value.length)
        if(value.length <10){
          callback(new Error('请输入10字以上的专辑介绍'));
        }else {
          callback();
        }
    };
    return {
      uploadApi: process.env.VUE_APP_BASE_API + '/api/file/upload',
      isNewBuild: false,
      activeFlag: false,
      searchName: '',
      albumList:[],
      albumObj:{
        upImage:'', //上传图片
        name:'', //专辑名
        singerName:'',//所属艺人
        schools:[],//流派
        language:'',//语种
        introduce:'',//介绍
        issue:'0',//是否发行
        issuePlatform:'' //已发行平台
      },
      rules:{
        name: [{ required: true, message: '请输入专辑名称', trigger: 'blur'}],
        upImage:{required: true,message: '请上传图片'},
        singerName:{required:true, message: '请输入艺人名称', trigger: 'blur'},
        schools:{required:true , message:'请选择流派', trigger: 'blur'},
        introduce: [{required:true, message:'请输入专辑介绍', trigger:'blur'},{validator:validatePass, trigger: 'blur'}],
        language:[
          {required:true, message:'请输入语种', trigger:'blur'},
        ],
      }
    }
  },
  created() {

  },
  methods:{
    //删除已选中专辑
    isNewBuildFn(){
      this.isNewBuild = true;
      this.$emit('emptyFn')
    },
    //搜索
    searchFn(){
      clearInterval(timer)
      let regu = "^[ ]+$";
      let re = new RegExp(regu);
      if(!re.test(this.searchName)){
        if( (!this.searchName && this.albumList.length === 0) || this.searchName){
          timer = setTimeout(()=>{
            this.obtainAlbum()
          },800)
        }
      }
    },
    // //新增按钮
    newlyAdded(){
      this.activeFlag = !this.activeFlag
      if(this.activeFlag){
        this.obtainAlbum()
      }else {
        this.albumList = []
      }
    },
    //点击 选中专辑
    selectItemFn(val){
      let {id,cover,name} = val;
      this.$emit('albumDetailsFn',id)
      if(val.status !== 4){
        this.$emit('modifySelectAlbum',{id, cover,name})
      }else {
        this.isNewBuild = false
      }
    },
    //删除已选中专辑
    deleteSelectedAlbum(){
      this.$emit('modifySelectAlbum',{})
      this.$emit('emptyFn')
    },
    obtainAlbum(){
      let p = {
        pageNum:1,
        pageSize:99,
        name:this.searchName,
        status:4
      }
      albumList(p).then(res=>{
        if(res.code === 0){
          this.albumList = res.data.list
        }
        this.activeFlag = true
      })
    },
  // 上传成功
    onSuccessFn(file){
      this.albumObj.upImage = file.url;
      this.$refs.form.clearValidate('upImage');
    },
  // 上传前
   async beforeUpload(file){
      //进行图片类型和大小的限制
      var testmsg=file.name.substring(file.name.lastIndexOf('.')+1)
      const extension = testmsg === 'png'
      const extension2 = testmsg === 'jpg'
      const extension3 = testmsg === 'jpeg'
      if(!extension && !extension2 && !extension3) {
        this.$message({
          message: '展会广告图只能是 png、jpg、jpeg、gif格式!',
          type: 'warning'
        });
        return false;//必须加上return false; 才能阻止
      }
      const isSize = await new Promise(function(resolve, reject){
        let width = 1000;
        let height = 1000;
        let _URL = window.URL || window.webkitURL;
        let img = new Image();
        img.onload = function(){
          let valid = img.width >= width && img.height >= height;
          valid ? resolve() : reject();
        }
        img.src = _URL.createObjectURL(file);
      }).then(()=>{
        return file;
      }, ()=>{
        this.$popup({
          msg:'图片尺寸需要 ≥ 1000*1000像素!',
          type: 'warning'
        });
        return Promise.reject()
        // return false;//必须加上return false; 才能阻止
      })
      return extension || extension2 || extension3 ||  isSize;

    },
  // 上传专辑
    uploadAlbumFn(val){
      this.$refs.form.validate(valid => {
        if(valid === true){
          let p = {
            albumName:this.albumObj.name,
            albumUrl:this.albumObj.upImage,
            desp:this.albumObj.introduce,
            issuePlatform:this.albumObj.issuePlatform,
            issued:this.albumObj.issue,
            schools:this.albumObj.schools.join(','),
            language:this.albumObj.language,
            albumMusician:this.albumObj.singerName
           }
          if(this.albumObj.id){
            p.id = this.albumObj.id
          }

          if(this.$parent.$parent.currentStep === 2 || (val === 0 && this.$parent.$parent.currentStep === 1)){
            albumSubmit(p).then(res=>{
              if(res.code === 0){
                this.$emit('modifySelectAlbum',{id:res.albumId,cover:p.albumUrl,name:p.albumName})
                this.$emit('uploadSongsFn',val)
              }
            })
          }else {
            if(this.songListJudgment() >-1){
              this.$popup({msg:"请提交歌曲名和基本信息"})
            }else{
              this.$parent.$parent.currentStep ++
            }
          }
        }
      })
    },
    selectChang(){
      this.$refs.form.clearValidate('schools');
    },
    selectChang1(){
      this.$refs.form.clearValidate('language');
    }
  },
  watch:{
   schoolsList(val){
     this.albumObj.schools = [val[0].value]
   },
    languageList(val){
      this.albumObj.language =val[0].value
    },
    musicianAlbum(val){
     this.musicianAlbum = val
      if(this.$route.params.id !=='0'&& this.musicianAlbum !== null || val.status === 4 ){
        let p = this.albumObj
        let a =this.musicianAlbum
        p.upImage = a.cover || ''
        p.name = a.name || ''
        p.singerName = a.albumMusician
        p.schools = a.schools!== undefined ? a.schools.split(','):[]
        p.language = a.language || ''
        p.introduce =a.desp
        p.issue = a.issueStatus !== undefined ?a.issueStatus.toString():''
        p.issuePlatform = a.issuePlatform || ''
        p.id = a.id
        this.albumObj = p
       if(this.musicianAlbum.uid !== undefined ){
         this.isNewBuild = false
       }
      }
    },
    albumObj:{ //监听的对象
      deep:true, //深度监听设置为 true
      handler:function(newV){
        if(newV.upImage === '' || newV.introduce === ''  ||newV.singerName === '' || newV.schools.length ===0 || newV.language === '' || newV.introduce === ''){
        this.$parent.$parent.flage2 = true

        }else {
          this.$parent.$parent.flage2 = false
        }
      }
    },
  },
}
</script>

<style scoped lang="scss">
.big {
  margin-top: 20px;
  background: #ffffff;
  border-radius: 4px;
  padding: 24px 40px;
  box-sizing: border-box;
  .title-header {
    font-size: 16px;
    font-family: PingFang SC-Medium;
    font-weight: 500;
    text-align: LEFT;
    color: #333333;
  }
  .selectSlbum{
    margin-top: 20px;
    height: 77px;
    background: #fcfcfc;
    border: 1px solid #dedede;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:{
      left: 14px;
      right: 34px;
    };
    .l{
      font-size: 14px;
      font-family:PingFang SC-Regular;
      font-weight: 400;
      color: #333333;

      img{
        width: 50px;
        height: 50px;
        margin-right: 14px;
        vertical-align: middle;
        border-radius: 2px;
      }
    }
    .r{
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }
  .unchecked{
    .choose-album {
      .header {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 77px;
        background: #fcfcfc;
        border: 1px solid #dedede;
        border-radius: 4px;
        font-size: 14px;
        font-family: PingFang SC-Regular;
        font-weight: 400;
        color: #666666;

        .left {
          margin-left: 17px;
          height: 100%;
          flex: 1;

          .el-input {
            height: 100%;

            ::v-deep .el-input__inner {
              height: 100%;
              border: none;
            }

            ::v-deep .el-input__prefix {
              display: flex;
              align-items: center;

              img {
                width: 22px;
                height: 22px;
              }
            }
          }

          .search {
            width: 22px;
            height: 22px;
            vertical-align: middle;
          }
        }

        .right {
          display: flex;
          align-items: center;
          margin-right: 110px;
          font-size: 12px;

          .up {
            width: 24px;
            height: 24px;
            cursor: pointer;
          }

          .line {
            width: 1px;
            height: 34px;
            background-color: #dedede;
            margin: 0 40px;
          }

          .newAdd {
            cursor: pointer;

            .add {
              vertical-align: middle;
              cursor: pointer;
              width: 16px;
              height: 16px;
              margin-right: 8px;
            }
          }

        }
      }

      .content {
        height: 0;
        overflow: hidden;
        opacity: 0;
        background: #ffffff;
        border: 1px solid #dedede;
        border-radius: 4px;
        box-sizing: border-box;
        transition: all .5s;
        padding: 20px 0 0 20px;
        display: grid;
        grid-template-columns:repeat(8, 90px);
        grid-column-gap: 50px;
        grid-row-gap: 40px;

        .newlyBuild {
          width: 90px;
          height: 91px;
          background: #fcfcfc;
          border: 1px solid #dedede;
          border-radius: 4px;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          justify-content: center;

          P {
            font-size: 14px;
            font-family: PingFang SC-Regular;
            font-weight: 400;
            text-align: center;
            color: #999999;
            margin: 0;
          }
        }

        .albumItem {
          cursor: pointer;
          img {
            width: 90px;
            height: 90px;
            border-radius: 2px;
          }

          font-family: PingFang SC-Regular;
          font-weight: 400;
          text-align: LEFT;
          color: #333333;

          p {
            margin: 0;
          }

          .title {
            font-size: 14px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }

          .title-name {
            font-size: 12px;
          }
        }
      }
      .content::-webkit-scrollbar {
        /*滚动条整体样式*/
        width : 10px;  /*高宽分别对应横竖滚动条的尺寸*/
        height: 1px;
      }
      .content::-webkit-scrollbar-thumb {
        /*滚动条里面小方块*/
        width: 6px;
        height: 40px;
        background: #e7e7e7;
        border-radius: 90px;
      }
      .activeContent {
        height: 181px;
        opacity: 1;
        overflow-y:scroll;
      }
    }

    .new-built {
      margin-top: 20px;

      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 24px;
        height: 60px;
        background: #f3f3f3;

        .l {
          font-size: 14px;
          font-family: PingFang SC-Regular;
          font-weight: 400;
          text-align: LEFT;
          color: #333333;
        }

        .r {
          width: 24px;
          height: 24px;
        }
      }

      .section {
        margin: 0;
        background: #fcfcfc;
        box-sizing: border-box;
        padding: 40px 70px;
        list-style: none;
         ::v-deep .el-form{
           .el-form-item{
             .el-form-item__label{
               height: 35px;
               line-height: 35px;
               font-size: 14px;
               font-family: PingFang SC-Light;
               font-weight: 300;
               text-align: LEFT;
               color: #555555;
             }
           }
         }
        .list {
          align-items: flex-start;
        }

        //li {
          //margin-bottom: 24px;
          //display: flex;
          //align-items: center;
          .content {
            ::v-deep .el-input__inner {
              border: 1px solid #e8e8e8;
              border-radius: 4px;
              width: 454px;
              height: 35px;
              background: #ffffff;
              padding-left: 15px;
              box-sizing: border-box;
            }
            ::v-deep .el-textarea{
              width: 454px;
            }
            ::v-deep .el-textarea__inner {
              width: 454px;
              height: 111px;
              background: #ffffff;
              border: 1px solid #e8e8e8;
              border-radius: 4px;
            }
          }

          .uploadImg {
            width: 130px;
            height: 130px;
          }

          .uploadBut {
            margin-left: 20px;

            .uploadBut-header {
              width: 171px;
              height: 44px;
              font-size: 12px;
              font-family: PingFang SC, PingFang SC-Regular;
              font-weight: 400;
              text-align: LEFT;
              color: #999999;
              line-height: 22px;
              margin-bottom: 20px;
            }

            .el-upload {
              .el-button {
                width: 80px;
                height: 31px;
                background: #ffffff;
                border: 1px solid #dedede;
                border-radius: 4px;
                text-align: center;
                padding: 0;
                font-size: 12px;
                font-family: PingFang SC-Regular;
                font-weight: 400;
                color: #555555;
              }
              .active:hover{
                border: 1px solid #ff004d;
                border-radius: 4px;
                color: #FF004D;
              }
            }
          }
        //}
      }
    }
  }

}
</style>
