<template>
  <div class="bigTwo">
    <div class="header-title">
      <span>声明</span>
      ：以下信息仅作为确认授权期望，具体授权情况以实际签署合同为准。
    </div>
    <el-form :model="fromobj" label-width="114px" :rules="rules" ref="form">
      <ul class="section">
        <el-form-item label="期望首发时间" prop="expectFirstRoundTime">
          <div class="content">
            <el-date-picker
                type="date"
                :picker-options="pickerOptions"
                v-model="fromobj.expectFirstRoundTime"
                placeholder="审核通过，立即发布">
            </el-date-picker>
          </div>
        </el-form-item>
        <el-form-item label="期望授权年限" prop="expectLimit">
          <div class="content">
            <el-select placeholder="永久" v-model="fromobj.expectLimit">
              <el-option value="2" label="2年"></el-option>
              <el-option value="3" label="3年"></el-option>
              <el-option value="5" label="5年"></el-option>
              <el-option value="永久" label="永久"></el-option>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item label="期望授权地区" prop="expectDistrict">
          <div class="content">
            <el-select v-model="fromobj.expectDistrict">
              <el-option value="中国大陆" label="中国大陆"></el-option>
              <el-option value="全世界" label="全世界"></el-option>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item label="不发行平台" prop="notDistributionPlatform">
          <div class="content">
            <el-input placeholder="请输入不发行平台" v-model="fromobj.notDistributionPlatform">
            </el-input>
          </div>
        </el-form-item>
        <el-form-item v-if="whether" label="是否允许翻唱" placeholder="请选择是否允许翻唱" prop="isCoverAllowed">
          <div class="content">
            <el-select @change="selectFn" v-model="fromobj.isCoverAllowed">
              <el-option value="0" label="否"></el-option>
              <el-option value="1" label="是"></el-option>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item label="其他发行要求" prop="otherDesp">
          <div class="content">
            <el-input resize="none" v-model="fromobj.otherDesp" type="textarea" placeholder="请输入其他发行要求...">
            </el-input>
          </div>
        </el-form-item>
      </ul>
    </el-form>
  </div>
</template>

<script>
import {authorizationSubmit} from '../../../api/song'

export default {
  name: "stepTwo",
  /**
   * albumId
   * 专辑id
   *
   * expectDistrict
   * 期望授权地区
   *
   * expectFirstRoundTime
   * 期望首发时间
   *
   * expectLimit
   * 期望授权年限
   *
   * isCoverAllowed
   * 是否允许翻唱 0否 1是
   *
   * notDistributionPlatform
   * 不发行平台
   *
   * otherDesp
   * 其他发行要求
   * */
  data() {

    return {
      pickerOptions: {
        // 限制预约时间
        disabledDate(time) {
          return time.getTime() < Date.now() - 24 * 60 * 60 * 1000
        }
      },
      list: [],
      fromobj: {
        albumId: '',
        expectDistrict: '全世界',
        expectFirstRoundTime: '',
        expectLimit: '永久',
        isCoverAllowed: "",
        notDistributionPlatform: '',
        otherDesp: '',
      },
      rules: {
        expectDistrict: {required: true, message: '请选择是否期望授权地区', trigger: 'blur'},
        expectLimit: {required: true, message: '请选择是否期望授权年限', trigger: 'blur'},
        isCoverAllowed: {required: true, message: '请选择是否翻唱', trigger: 'blur'},
      },
      whether: true,
    }
  },
  methods: {
    //提前判断是否填写完成
    submitFlay1(){
      this.$refs.form.validate((valid) =>{
        this.$parent.Authorization = valid
      })
    },
    submitFlay(val) {
      this.$refs.form.validate((valid) => {

        if (valid) {
          this.fromobj.albumId = this.$parent.$refs.stepOne.selectAlbum.id
          if (!this.fromobj.expectFirstRoundTime) {
            this.fromobj.expectFirstRoundTime = ''
          }
          if(!this.whether){
             this.fromobj.isCoverAllowed = ''
          }
          authorizationSubmit(this.fromobj).then(res => {
            if (res.code === 0) {
              if (val === 0) {
                this.$popup({msg: '保存草稿成功', type: 'success'})
                this.$emit('continueSubmission')
                this.$parent.currentStep = 1
                this.$router.replace('/song-draft')
                // if(this.$route.params.id !== '0'){
                //   this.$router.replace('/release/0')
                // }
              } else {
                this.$parent.currentStep++
              }
            }
          })
        }
      })
    },
    //  获取填充数据
    getPopulationData(val) {
      val.isCoverAllowed = val.isCoverAllowed.toString()
      this.fromobj = val
    },
    selectFn() {
      this.$refs.form.clearValidate('isCoverAllowed')
      this.$parent.Authorization = true
     },
    PublicAddTwoFn(val) {
      this.list = val
      if (this.$store.state.usermsg.webLoginType) {
        this.whether = true
      } else {
      this.whether = val.some(item => {
          return item.composer == item.lyricist && item.lyricist == this.$store.state.usermsg.name
        })
      }
    }
  },
}
</script>

<style scoped lang="scss">
.bigTwo {
  margin-top: 20px;
  height: 574px;
  background: #ffffff;
  border-radius: 4px;
  padding: 30px 40px;

  .header-title {
    padding: 0 14px;
    height: 34px;
    background: #f5f5f5;
    border-radius: 3px;
    line-height: 34px;
    font-size: 12px;
    font-family: PingFang SC-Regular;
    font-weight: 400;
    text-align: LEFT;
    color: #333333;

    span {
      font-weight: 600;
    }
  }

  ::v-deep .el-form {
    .el-form-item {
      height: 43px;

      .el-form-item__label {
        font-size: 14px;
        font-family: PingFang SC-Light;
        font-weight: 300;
        text-align: LEFT;
        color: #555555;
        height: 43px;
        line-height: 43px;
      }
    }
  }

  .section {
    padding: {
      padding-left: 30px;
    }
    margin-top: 30px;
    list-style: none;

    .content {
      ::v-deep .el-input__inner {
        width: 454px;
        height: 43px;
        background: #ffffff;
        border: 1px solid #e8e8e8;
        border-radius: 4px;
        padding: 0 15px;
      }

      ::v-deep .el-date-editor {
        .el-input__inner {
          padding: 0 30px;
        }
      }

      ::v-deep .el-textarea__inner {
        width: 454px;
        height: 111px;
        background: #ffffff;
        border: 1px solid #e8e8e8;
        border-radius: 4px
      }
    }
  }
}
</style>
