<template>
<div class="stepThree">
  <img src="../../../assets/successIcon.jpg">
   <div class="title">提交成功！</div>
   <div class="sub-title">请等待作品审核~</div>
  <el-button @click="fn()">继续发布作品</el-button>
</div>
</template>

<script>
export default {
  name: "stepThree",
  methods:{
    fn(){
      if(this.$route.params.id === "0"){
        this.$router.go(0)
      }else {
        this.$router.replace('/release/0')
        this.$parent.currentStep = 1
        this.$emit('continueSubmission')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.stepThree{
  margin-top: 20px;
  height: 726px;
  background: #ffffff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  img{
    width: 100px;
    height: 100px;
  }
  .el-button{
    width: 124px;
    height: 40px;
    border-radius: 2px;
    background: #ff004d;
    font-size: 14px;
    font-family: PingFang SC-Medium;
    font-weight: 500;
    color: #ffffff;
  }
  .title{
    font-size: 16px;
    font-family: PingFang SC-Medium;
    font-weight: 500;
    color: #333333;
    text-align: center;
    margin-top: 20px;
  }
  .sub-title{
    font-size: 14px;
    font-family: PingFang SC-Regular;
    font-weight: 400;
    color: #666660;
    text-align: center;
   margin:{
     top:20px;
     bottom:10px;
   };
  }
}
</style>
