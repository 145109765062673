<template>
  <div class="stepOne">
    <add-song :schoolsList.sync="schoolsList"
              :languageList.sync="languageList"
              :headers="headers"
              :songList="songList"
              :currentIndex="currentIndex"
              @isCurrentIndex="isCurrentIndex"
              ref="addSong"
    >

    </add-song>
    <uploadAlbum :schoolsList.sync="schoolsList"
                 :languageList.sync ="languageList"
                 :headers="headers"
                 :selectAlbum="selectAlbum"
                 @modifySelectAlbum="modifySelectAlbum"
                 ref="uploadAlbum"
                 @uploadSongsFn="uploadSongsFn"
                 :musicianAlbum.sync="musicianAlbum"
                 @albumDetailsFn="albumDetailsFn"
                 @emptyFn="emptyFn"
                 :songListJudgment="songListJudgment"
    >

    </uploadAlbum>
  </div>
</template>

<script>
import addSong from './addSong'
import uploadAlbum from './uploadAlbum'
import store from "@/store/index";
import {dictInfo} from "@/api/dictionary";
import {songSubmit, albumDetail, songList} from  '@/api/song'
export default {
  name: "stepOne",
  components: {
    addSong, uploadAlbum
  },
  created() {
    if(this.$route.params.id !== '0'){
      this.albumDetailsFn(this.$route.params.id)

    }
    this.languageFn()
    this.schoolsFn()
  },
  /**
   * currentIndex记录歌曲当前下标
   * 歌曲总数
   * */
  data() {
    return {
      headers: {
        'X-EFRESH-SECRET': store.state.secret,
        'X-EFRESH-SESSION': store.state.session
      },
      currentIndex: 0,
      songList: [],
      selectAlbum: {},
      languageList: [],
      schoolsList: [],
      //当为修改专辑是获取的专辑详情
      musicianAlbum:{}
    }
  },
  methods: {
    emptyFn(){
      this.selectAlbum = {}
      this.musicianAlbum = {}
      let songList =[]
      let showSongList = []
      this.songList.forEach((item,index)=>{
        if(!item.id){
          songList.push(this.songList[index])
          showSongList.push(this.$refs.addSong.showSongList[index])
        }
      })
      if(songList.length > -1){
        this.currentIndex = 0
      }
      this.songList = songList
      this.$refs.addSong.showSongList = showSongList
    },
    //点击继续提交去清空页面
    continueSubmission(){
      this.selectAlbum = {}
      this.musicianAlbum = {}
      this.songList = []
      this.$refs.addSong.showSongList = []
    },
    modifySelectAlbum(val) {
      this.selectAlbum = val
    },
    //修改当前值
    isCurrentIndex(val) {
      this.currentIndex = val;
    },
    //  languageFn 语言
    languageFn() {
      dictInfo({"codes": ["10046"]}).then(res => {
          if (res.code === 0) {
            this.languageList = res.data
        }
      })
    },
    //  schoolsFn 流派
    schoolsFn() {
      dictInfo({"codes": ["10045"]}).then(res => {
        {
          if (res.code === 0) {
            this.schoolsList = res.data
          }
        }
      })
    },
    //Submit 下一步
    submitFn(val){
      //判断是否上传歌曲和专辑
      if(this.songList.length ===0){
        return
      }else {
         //判断专辑
         if(this.selectAlbum.id === undefined){
           // 判断是否新建专辑
           if(this.$refs.uploadAlbum.isNewBuild){
              this.$popup({msg:'请选择专辑'})
           }else {
             this.$refs.uploadAlbum.uploadAlbumFn(val)
           }
         }else {
           this.uploadSongsFn(val)
         }
      }
    },
    //  上传歌曲
    uploadSongsFn(val) {
        let index = this.songListJudgment()
      if(index < 0){
        if( this.$parent.currentStep === 1 && val === 1){
          this.$parent.currentStep ++
          return
        }
        if(!this.$parent.Authorization && this.$parent.currentStep === 2){
          return
        }
        this.songList.forEach(item =>{
          item.schools = [...item.schools].join(',')
          item.activityIds = [...item.activityIds].join(',')
        })
        let p = {
          albumId:this.selectAlbum.id,
          songFormList:this.songList,
          status:val
        }
        songSubmit(p).then(res => {
          this.songList.forEach(item =>{
            item.schools = item.schools.split(',')
            if(item.activityIds){
              item.activityIds = item.activityIds.split(',')
            }else {
              item.activityIds = []
            }
          })
          if(res.code === 0){
            //下一步
            if(this.$parent.currentStep === 1 && val === 0){
              this.$popup({msg:'保存草稿成功',type:'success'})
              this.$emit('continueSubmission')
              this.$router.replace('/song-draft')
              // if(this.$route.params.id !== '0'){
              //    this.$router.replace('/release/0')
              // }
            }else {
              this.$emit('submitAuthorization' ,val)
            }
          }
        })
      }else {
        this.$popup({msg:"请提交歌曲名和基本信息"})
      }

    },
    //获取专辑详情
    albumDetailsFn(val){
      albumDetail({},{albumId:val}).then(res=>{
        if(res.code === 0){
          this.musicianAlbum = res.data.musicianAlbum
          this.$emit('albumContentFn',res.data.musicianAlbum)
          this.albumDetailFn(val)
          let a = res.data.musicianAlbum
          console.log(a.isCoverAllowed)
          let p = {
            expectFirstRoundTime:a.expectFirstRoundTime || '',
            expectLimit:a.expectLimit || '永久',
            expectDistrict:a.expectDistrict || '全世界',
            notDistributionPlatform:a.notDistributionPlatform || '',
            isCoverAllowed:a.isCoverAllowed !== undefined ? a.isCoverAllowed.toString() :'',
            otherDesp:a.otherDesp || ''
          }
          this.$emit('dataFilling',p)
        }
      })
    },
  // 获取歌曲信息
    albumDetailFn(val){
      songList({},{albumId:val}).then(res=>{
        if(res.code === 0 && res.data.length > 0){
          res.data.forEach(item =>{
            item.language = item.language || ''
              item.schools = item.schools !== undefined ?item.schools.split(',') : []
              item.activityIds = item.activityIds !== undefined ?item.split(',') : []
              this.$refs.addSong.showSongList.push({speed:100,name:item.name||'无名歌曲'})
              item.songName = item.name || ''
              delete item.name
               item.accompanyUrl = item.accompanyPath || ''
                delete item.accompanyPath
          })
          if(this.songList.length >0 ){
            this.songList.push(...res.data)
          }else {
            this.songList = res.data
          }
        }
      })
    },
    songListJudgment(){
      var regu = "^[ ]+$";
      var re = new RegExp(regu);
      let index = this.songList.findIndex(item=>{
        return  re.test(item.songName) || item.songName === '' ||  re.test(item.singer) || item.singer === '' || re.test(item.lyricist) ||  item.lyricist === '' || re.test(item.composer) || item.composer === ''||item.schools.length === 0 ||item.language.length === 0
      })
      if(index > -1){
        this.currentIndex = index
        this.$refs.addSong.stretch = true
      }
      return index
    }
  },
  watch:{
    songList(val){

      if(val.length ===0){
        this.$parent.flage1 =true
      }else {
        this.$parent.flage1 =false
      }
    },
  }
}
</script>

<style scoped>

</style>
