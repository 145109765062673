<template>
  <div class="big">
    <div class="addTitle">添加歌曲</div>
    <div class="uploadList" v-if="showSongList.length > 0">
      <div class="left" :style="{width:stretch ?'50%':'100%'}">
        <div class="uploads"  @click="modifyCurrentIndex(index)" v-for="(item, index) in showSongList" :key="index" :class="{active:currentIndex === index}"  >
          <p>{{item.name}}</p>
          <div v-show="!stretch" ><img src="../../../assets/release/success.png" v-show="item.speed === 100" > {{item.speed===100?'上传成功':`${item.speed}%`}} <span @click.stop="dialogVisibleFn2(index)">删除</span></div>
          <div v-show="!stretch" class="details" @click.stop="detailsFn(index)">详情 <img :src="stretchImgFn"></div>
        </div>
      </div>
      <div class="right" v-if="stretch">
        <div class="details">
          <div class="d1"><img style="width: 10px;height: 10px;vertical-align: middle" src="../../../assets/release/success.png"  v-show="showSongList[currentIndex].speed === 100" > {{showSongList[currentIndex].speed===100?'上传成功':`${showSongList[currentIndex].speed}%`}}</div>
          <div class="d2" @click="dialogVisibleFn">删除</div>
          <div class="d3" @click="stretch = !stretch">详情 <img src="../../../assets/release/upper.png"></div>
        </div>
        <p class="header-title">歌曲信息</p>
        <ul>
          <li><span class="label"><i class="stars">*</i>歌曲名</span>
            <div class="content">
              <el-input v-model="$parent.songList[currentIndex].songName" placeholder="请输入歌曲名称"></el-input>
            </div>
          </li>
          <li><span class="label"><i class="stars">*</i>流派</span>
            <div class="content">
              <el-select v-model="$parent.songList[currentIndex].schools"  placeholder="请选择流派" multiple>
                <el-option
                    v-for="item in schoolsList"
                    :key="item.value"
                    :label="item.value"
                    :value="item.value">
                </el-option>
              </el-select>
            </div>
          </li>
          <li><span class="label"><i class="stars">*</i>语言</span>
            <div class="content">
              <el-select v-model="$parent.songList[currentIndex].language"  placeholder="请选择语言">
                <el-option
                    v-for="item in languageList"
                    :key="item.value"
                    :label="item.value"
                    :value="item.value">
                </el-option>
              </el-select>
            </div>
          </li>
          <li><span class="label">
            <i class="stars">*</i>
            表演者</span>
            <div class="content">
              <el-input v-model="$parent.songList[currentIndex].singer" placeholder="请输入歌手姓名"></el-input>
            </div>
          </li>
          <li><span class="label">
            <i class="stars">*</i>
            词作者</span>
            <div class="content">
              <el-input v-model="$parent.songList[currentIndex].lyricist" placeholder="请输入作者姓名"></el-input>
            </div>
          </li>
          <li><span class="label">
            <i class="stars">*</i>
            曲作者</span>
            <div class="content">
              <el-input v-model="$parent.songList[currentIndex].composer" placeholder="请输入作者姓名"></el-input>
            </div>
          </li>
          <li><span class="label">活动</span>
            <div class="content">
              <el-select v-model="$parent.songList[currentIndex].activityIds" placeholder="请选择活动"  multiple :disabled="activityDisabled">
                <el-option
                    v-for="item in options"
                    :key="item.id"
                    :label="item.title"
                    :value="item.id"
                    :disabled="selectedActivity.indexOf(item.id) > -1 && $parent.songList[currentIndex].activityIds.indexOf(item.id) === -1"
                >
                </el-option>
              </el-select>
            </div>
          </li>
          <li><span class="label">制作信息</span>
            <div class="content">
              <el-input v-model="$parent.songList[currentIndex].productionMsg" placeholder="可填写编曲师、混音师、录音师等信息"></el-input>
            </div>
          </li>
          <li><span class="label">歌词</span>
            <div class="content">
              <el-input v-model="$parent.songList[currentIndex].lrc" type="textarea" resize="none" placeholder="请输入歌曲名称"></el-input>
            </div>
          </li>
          <li><span class="label">伴奏</span>
            <div class="content">
              <el-upload
                  class="upload-demo"
                  :before-upload="beforeUpload1"
                   :action="uploadApi"
                   :show-file-list="false"
                   :on-success="onSuccessAccompany"
                  :headers="{...headers,fileType:'audio'}"
              >
                <div class="xiugai"  v-if="$parent.songList[currentIndex].accompanyUrl">
                  <div class="l">
                    {{$parent.songList[currentIndex].accompanyUrl |interception }}
                  </div>
                  <div class="r">
                    <img class="yes" src="../../../assets/release/yes.png">
                    <span>上传成功</span>
                    <img @click="deleteStyle()" class="trashcan" src="../../../assets/release/trashcan.png">
                  </div>
                </div>
                <div class="uploadStyle" v-else  @mouseover="activeType = true"
                     @mouseleave="activeType = false" :class="{active:activeType}">
                  <span>+</span>
                  <span>上传伴奏</span>
                </div>
              </el-upload>
            </div>
          </li>
          <li>
            <span class="label"></span>
                <div class="putaway" @click="stretch = false">
                  <span>收起</span>
                  <img src="../../../assets/release/upper.png">
                </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="uploadfirst">
      <el-upload
          :show-file-list="false"
          drag
          :headers="{...headers,fileType:'audio'}"
          class="upload-demo"
          :before-upload="beforeUpload"
          :on-success="onSuccess"
          :on-progress="onProgress"
          :action="uploadApi">
        <div class="beforeUpLoading" :class="{active: activeSong }" @mouseover="activeSong = true"
             @mouseleave="activeSong = false">
          <div><img src="../../../assets/release/uploadSongs.png">上传歌曲文件</div>
          <div>歌曲必须为wav、mp3格式，大小控制在200M以内</div>
        </div>
      </el-upload>
    </div>
    <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
        width="480px"
        align="center"
    >
      <span>删除后不可撤回，请确认是否删除？</span>
      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button @click="deleteCurrent" class="activeBut" type="primary">确 定</el-button>
  </span>
    </el-dialog>
   <!--  添加歌曲收起后展示的弹窗 -->
    <el-dialog
        title="提示"
        :visible.sync="dialogVisible2"
        width="480px"
        align="center"
    >
      <span>删除后不可撤回，请确认是否删除？</span>
      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible2= false">取 消</el-button>
    <el-button @click="deleteWork(deleteCurrentIndex)" class="activeBut" type="primary">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import {activityList,songDelSong} from '@/api/song'
import lower from '../../../assets/release/lower.png'
import upper from '../../../assets/release/upper.png'
export default {
  name: "addSong",
  props:{
    songList:Array,
    headers:Object,
    currentIndex:Number,
    languageList:Array,
    schoolsList:Array
  },
  computed:{
    selectedActivity(){
      let c = []
      this.songList.forEach(e => {
        c = [...c, ...e.activityIds]
      })
      return c
    },
    activityDisabled(){
      if(this.$route.params.id === '0'){
        return false
      }else {
        if(this.$parent.musicianAlbum.status === 4){
          return false
        }else {
          return true
        }
      }
    },
    stretchImgFn(){
      if(this.stretch){
        return upper
      }else {
        return lower

      }
    }
  },
  data() {
    return {
      uploadApi: process.env.VUE_APP_BASE_API + '/api/file/upload',
      activeSong: false,
      activeType: false,
      showSongList:[],
    //  活动
      options:[],
      stretch:true,
    // 删除弹框
      dialogVisible:false,
      dialogVisible2:false,
      //删除当前索引
      deleteCurrentIndex:0
    }
  },
  mounted() {
      this.activityListFn()
  },
  methods:{
    beforeUpload(file){
      let index = file.name.lastIndexOf('.')
      let  type = file.name.substring(index + 1, file.name.length)
      const isLt2M = file.size / 1024 / 1024 < 200
      let  whileList = ['mp3', 'wav']
       if(whileList.indexOf(type) > -1){
         if(!isLt2M){
           this.$popup({msg:'文件大小不能超过200MB'})
           return  false
         }else {
           this.showSongList.push({name:file.name,speed :0})
           let p = {
             accompanyUrl:'', //伴奏url
             activityIds:[] ,//活动id
             composer:'',//作曲
             lrc:'',//歌词
             lyricist:'',//作词
             singer:'',//歌手名
             songName:'',//歌曲名
             productionMsg:'',//制作信息
             songUrl:'', //歌曲url
             language:this.languageList[0].value, //语言
             schools:[this.schoolsList[0].value] ,//流派
           }
           this.$parent.songList.push(p)
           this.$emit('isCurrentIndex',this.showSongList.length - 1)
           this.stretch = true
           return true
         }
       }else {
         this.$popup({msg:'请输入正确的音频文件'})
         return  false
       }

    },
    //上传伴奏
    beforeUpload1(file){
      let index = file.name.lastIndexOf('.')
      let  type = file.name.substring(index + 1, file.name.length)
      const isLt2M = file.size / 1024 / 1024 < 200
      let  whileList = ['mp3', 'wav']
      if(whileList.indexOf(type) > -1){
        if(!isLt2M){
          this.$popup({msg:'文件大小不能超过200MB'})
          return  false
        }else {
          return true
        }
      }else {
        this.$popup({msg:'请输入正确的音频文件'})
        return  false
      }

    },
    //文件上传时
    onProgress(event){
      this.showSongList[this.currentIndex].speed = parseInt(event.percent)
    },
    /**
     * accompanyUrl  string
     * 伴奏url
     * activityIds  string
     * 活动id(多个逗号分割)
     * composer  string
     * 作曲
     * id  string
     * 歌曲id
     * lrc  string
     * 歌词
     * lyricist  string
     * 作词
     * singer  string
     * 歌手名
     * songName  string
     * 歌曲名
     * songUrl
     * 歌曲url
     *  language:[], //语言
     *  schools:[] //流派
     *  制作信息
     *  productionMsg
     * */
    onSuccess(response){
       if(response.code === 0){
         this.$parent.songList[this.currentIndex].songUrl = response.url
       }else {
         this.$parent.songList.length = this.$parent.songList.length - 1
         this.showSongList = this.$parent.songList.length - 1
         this.$popup({msg:response.msg})
       }
    },
  //  上传伴奏
    onSuccessAccompany(file){
      if(file.code === 0){
        this.$parent.songList[this.currentIndex].accompanyUrl = file.url
      }
    },
  // 修改但当前索引
    modifyCurrentIndex(val){
      this.$emit('isCurrentIndex',val)
    },
  // 删除作品
    deleteWork(val){
      if(this.showSongList.length - 1 === val){
        if(this.showSongList.length - 2 < 0){
          this.$emit('isCurrentIndex',0)
        }else {
          this.$emit('isCurrentIndex',this.showSongList.length - 2)
        }
      }
      if(!this.$parent.songList[val].id){
        this.showSongList.splice(val, 1)
        this.$parent.songList.splice(val, 1)
         this.dialogVisible2 = false
        return
      }
      songDelSong({},{songId:  this.$parent.songList[val].id}).then(res=>{
        if(res.code === 0){
          this.showSongList.splice(val, 1)
          this.$parent.songList.splice(val, 1)
          this.dialogVisible2 = false
        }
      })
    },
  // 删除伴奏
    deleteStyle(){
      this.$parent.songList[this.currentIndex].accompanyUrl = ''
    },
    activityListFn(){
      let p = {
        pageNum:1,
        pageSize:999,
        type:1
      }
      activityList(p).then(res=>{
        if(res.code === 0){
          res.data.list.forEach(item =>{
            let type = item.contributionsType.split(',')
            //
            if ((type.length > 1 || type[0] == 2) && item.isSign==0 && item.status==2) {
              this.options.push(item)
            }
          })
        }
      })
    },
  //  详情首所展开
    detailsFn(val){
      this.stretch = !this.stretch
      this.$emit('isCurrentIndex',val)
    },
  // 删除当前
    deleteCurrent(){
      if(this.showSongList.length - 1 === this.currentIndex){
        if(this.showSongList.length - 2 < 0){
          this.$emit('isCurrentIndex',0)
        }else {
          this.$emit('isCurrentIndex',this.showSongList.length - 2)
        }
      }
      if(!this.$parent.songList[this.currentIndex].id){
        this.showSongList.splice(this.currentIndex, 1)
        this.$parent.songList.splice(this.currentIndex, 1)
        this.dialogVisible = false
        return
      }
      songDelSong({},{songId:  this.$parent.songList[this.currentIndex].id}).then(res=>{
        if(res.code === 0){
          this.showSongList.splice(this.currentIndex, 1)
          this.$parent.songList.splice(this.currentIndex, 1)
          this.dialogVisible = false
        }
      })
    },
  // 弹窗显示1
    dialogVisibleFn(){
      this.dialogVisible = true
    },
    // 弹窗显示2
    dialogVisibleFn2(val){
      this.dialogVisible2 = true
      this.deleteCurrentIndex = val
    },

  },
    filters:{
    interception(val){
      let index = val.lastIndexOf('/')
      return  val.substring(index + 1, val.length)
    }
  },
}
</script>

<style scoped lang="scss">
.big {
  padding: 20px 40px 32px 40px;
  background-color: #ffffff;

  .uploadList {
    display: flex;
    margin-bottom: 42px;

    .left {
      width: 50%;
      .uploads {
        height: 50px;
        padding: 0 20px;
        box-sizing: border-box;
        white-space: nowrap;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #f1f1f1;
         p{
           flex: 1;
           overflow: hidden;
           text-overflow: ellipsis;
           white-space:nowrap;
         }
        div {
          width: 115px;
          font-size: 10px;
          font-family: PingFang SC-Regular;
          font-weight: 400;
          text-align: LEFT;
          color: #6baf0b;

          img {
            width: 10px;
            height: 10px;
          }

          span {
            color: #FF004D;
            margin: 0 10px 0 23px;
            cursor: pointer;
          }
        }
        .details{
          width: 30px;
          color: #FF004D;
          margin: 0 10px 0 23px;
          cursor: pointer;
          font-size: 10px;
          img{
            vertical-align: middle;
            width:16px;
            height: 16px;
          }
        }
      }
      .active {
        background: #f8f8f8;
      }
    }

    .right {
      * {
        padding: 0;
        margin: 0;
        list-style: none;
      }

      padding: 0 30px;
      width: 50%;
      min-height: 816px;
      background: #f8f8f8;
      .details{
        width: 100%;
        height: 50px;
         font-family:PingFang SC-Regular;
         font-weight: 400;
        font-size: 10px;
         color: #6baf0b;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        div{
          margin-right: 20px;
        }
        .d2{
          color: #ff004d;
          cursor: pointer;
        }
        .d3{
          margin-right: 0;
          color: #ff004d;
          cursor: pointer;
          img{
            vertical-align: middle;
            width: 16px;
            height: 16px;
          }
        }
          }
      .header-title {
        height: 50px;
        line-height: 50px;
        font-size: 14px;
        font-family: PingFang SC-Regular;
        font-weight: 400;
        text-align: LEFT;
        color: #333333;
      }

      ul {

        li {
          display: flex;
          margin-bottom: 26px;
          .label {
            line-height: 35px;
            width: 80px;
            font-size: 14px;
            font-family: PingFang SC-Light;
            font-weight: 300;
            text-align: LEFT;
            color: #555555;
            .stars{
              font-style: normal;
              margin-right: 3px;
              font-size: 14px;
              font-family: PingFang SC, PingFang SC-Regular;
              font-weight: 400;
              text-align: LEFT;
              color: #ff004d;
            }
          }
          .putaway{
            width: 454px;
            height: 34px;
            background: #ffffff;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            span{
              font-size: 12px;
              font-family: PingFang SC-Regular;
              font-weight: 400;
              text-align: LEFT;
              color: #ff004d;
            }
            img{
              width: 16px;
              height: 16px;
            }
          }
          .content {
            flex: 1;
             ::v-deep .el-select{
               width: 100%;
             }
            ::v-deep .el-input__inner {
              height: 35px;
              border: 1px solid #e8e8e8;
              border-radius: 4px;
              padding-left: 13px;
            }

            ::v-deep .el-textarea__inner {
              height: 201px;
              border: 1px solid #e8e8e8;
            }

            ::v-deep .el-upload {
              width: 100%;
              .uploadStyle {
                height: 77px;
                background: #ffffff;
                border: 1px solid #e8e8e8;
                border-radius: 4px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                span{
                  display: block;
                  font-size: 14px;
                  font-family:  PingFang SC-Light;
                  font-weight: 300;
                  color: #333333;
                }
              }
              .xiugai{
                width: 454px;
                height: 40px;
                box-sizing: border-box;
                border: 1px solid #e8e8e8;
                border-radius: 4px;
                padding: 0 20px;
                display: flex;
                justify-content: space-between;
                align-items:center;
                .r{
                  .yes {
                    width: 10px;
                    height: 9px;
                  }
                  .trashcan{
                    width: 18px;
                    height: 18px;
                    vertical-align: middle;
                  }
                  span{
                    vertical-align: middle;
                    font-size: 10px;
                    font-family: PingFang SC-Regular;
                    font-weight: 400;
                    color: #6baf0b;
                    margin-right: 20px;
                  }
                }
              }
              .active{
                background: rgba(255,0,77,0.05);
                border: 1px dashed  #ff004d;
                border-radius: 4px;
              }
            }
          }
        }
      }
    }
  }

  .uploadfirst {
    ::v-deep .el-upload {
      width: 100%;
      .el-upload-dragger{
        width: 100%;
        height: 107px;
        .beforeUpLoading {
          height: 107px;
          width: 100%;
          background: #fcfcfc;
          border-radius: 4px;
          display: flex;
          flex-direction: column;
          justify-content: center;

          div {
            font-family: PingFang SC-Regular;
            font-size: 12px;
            font-weight: 400;

            img {
              width: 16px;
              height: 16px;
              vertical-align: middle;
            }
          }

          div:nth-child(1) {
            color: #ff004d;
            margin-bottom: 9px;
          }

          div:nth-child(2) {
            color: #aaaaaa;
          }
        }
        .active {
          background: rgba(255, 0, 77, 0.05);
        }
      }
    }
  }

  .addTitle {
    font-size: 16px;
    font-family: PingFang SC-Medium;
    font-weight: 500;
    text-align: LEFT;
    color: #333333;
    margin-bottom: 20px;
  }
  ::v-deep .el-dialog {
    height: 260px;

    .el-dialog__header {
      padding: 30px 0;
      margin: 0;

      .el-dialog__title {
        font-size: 18px;
        font-family: PingFang SC-Medium;
        font-weight: 500;
        text-align: LEFT;
        color: #222222;
      }
    }

    .el-dialog__body {
      padding: 25px 0;
    }

    .el-dialog__footer {
      display: flex;
      justify-content: center;
      padding: 25px 0 40px 0;

      .el-button {
        width: 160px;
        height: 40px;
        background: #f4f4f4;
        border-radius: 3px;
        font-size: 14px;
        font-family: PingFang SC-Regular;
        font-weight: 400;
        color: #333333;
      }

      .activeBut {
        background: #ff004d;
        color: #ffffff;
        margin-left: 32px;
      }
    }
  }
}
</style>
